import { Controller, FieldValues } from 'react-hook-form';
import React, { ReactElement } from 'react';
import { DatePicker } from '@/common/ui/date-picker/date-picker';
import { TDatePickerControlledProps } from '@/common/ui/date-picker/types';


export function DatePickerControlled<TFieldValues extends FieldValues = FieldValues>({
    control,
    ...props
}: TDatePickerControlledProps<TFieldValues>): ReactElement {
    return (
        <Controller
            control={ control }
            name={ props.name }
            render={ ({ field }) => (
                <DatePicker
                    { ...field }
                    { ...props }
                    error={ props.error?.message }
                />
            ) }
        />
    );
}