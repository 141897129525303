import { DatePickerControlled } from '@/common/ui/date-picker/date-picker-controlled';
import { TDatePickerComplex, TDatePickerControlled } from '@/common/ui/date-picker/types';
import { DatePicker } from './date-picker'

(DatePicker as any as TDatePickerComplex).Controlled = DatePickerControlled as TDatePickerControlled;
const DatePickerComplex: TDatePickerComplex = DatePicker as any as TDatePickerComplex;

export {
    DatePickerComplex as DatePicker,
    DatePickerControlled,
};