import React from 'react';
import i18next from 'i18next';
import { createBrowserRouter } from 'react-router-dom';
import { RouterProvider } from 'react-router';
import { UILoadingOverlay } from 'finbox-ui-kit';
import { FUIModalsProvider } from 'finbox-ui-kit/context/modals.context';
import { CreditFormTranslation } from '@/components/leads/components/credit-from/translation';
import { AuthContextProvider } from '@/context/auth.context';
import { SocketsProvider } from '@/libs/sockets/sockets.context';
import '@/assets/scss/index.scss';
import AppRoot from './app-root';
import ErrorBoundary from './error-boundary';

i18next.init({
    lng: 'ru',
    debug: false,
    resources: {
        ru: {
            translation: CreditFormTranslation,
        },
    },
}).then(() => {
    // console.info('i18n initialized');
});

const router = createBrowserRouter([
    {
        path: "*",
        element: <AppRoot/>,
        errorElement: <ErrorBoundary/>,
    },
]);


function App() {
    return (
        <SocketsProvider>
            <AuthContextProvider>
                <FUIModalsProvider>
                    <RouterProvider
                        router={ router }
                        fallbackElement={ <UILoadingOverlay active/> }
                    />
                </FUIModalsProvider>
            </AuthContextProvider>
        </SocketsProvider>
    );
}

export default App;
