import React, { MouseEventHandler, useCallback } from 'react';
import { UIIconButton } from 'finbox-ui-kit';
import { UIIcon } from 'finbox-ui-kit/components/icon';
import { TUIIconName } from 'finbox-ui-kit/components/icon/icon';
import { TUIColor } from 'finbox-ui-kit/types/common';
import { formatDateTime } from 'finbox-ui-kit/utils/format-datetime';
import { TNotification } from '@/common/user-notifications/notifications.types';

const MLevelIcon = new Map<string, TUIIconName>([
    [ 'info', 'circle-info' ],
    [ 'warning', 'triangle-exclamation' ],
    [ 'error', 'circle-exclamation' ],
    [ 'success', 'circle-check' ],
]);

const MLevelColor = new Map<string, TUIColor>([
    [ 'info', 'blue' ],
    [ 'warning', 'orange' ],
    [ 'error', 'red' ],
    [ 'success', 'green' ],
]);

export type TNotificationProps = {
    notification: TNotification,
    onClick: (notification: TNotification) => void,
    onClickRemove: (notification: TNotification) => void,
    loadingRemove: boolean,
    disabledRemove: boolean,
}
export const Notification: React.FC<TNotificationProps> = ({
    notification,
    onClick,
    onClickRemove,
    loadingRemove,
    disabledRemove,
}) => {
    const handlerClickRemove: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        onClickRemove(notification);
    }, [ notification, onClickRemove ]);

    const handlerClickTitle = useCallback((e) => {
        e.preventDefault();
        onClick(notification);
    }, [ notification, onClick ]);

    return (
        <div className='notifications-item'>
            <div className='notifications-item-container' onClick={ handlerClickTitle }>

                <div className='notifications-item-title'>
                    <UIIcon
                        name={ MLevelIcon.get(notification.level) }
                        className='notifications-item-icon'
                        color={ MLevelColor.get(notification.level) }
                        type='solid'
                    />
                    { notification.link ? (
                        <a href='#' onClick={ handlerClickTitle }>{ notification.title }</a>
                    ) : (
                        <b>{ notification.title }</b>
                    ) }
                </div>
                <p dangerouslySetInnerHTML={{ __html: notification.body }} />
                <small>
                    { formatDateTime(notification.createdAt) }
                </small>
            </div>
            <div className='notifications-item-remove'>
                <UIIconButton
                    icon='xmark'
                    onClick={ handlerClickRemove }
                    loading={ loadingRemove }
                    disabled={ disabledRemove }
                />
            </div>
        </div>
    );
}
