import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { FieldError, useForm } from 'react-hook-form';
import { FORMAT } from 'finbox-ui-kit/consts';
import { TInitials } from 'finbox-ui-kit/types';
import { formatPrice } from '@/utils';
import { RequisitesView } from '@/components/leads/components/requisites/requisites-view';
import { RequisitesSend } from '@/components/leads/components/requisites/requisites-send';
import { API_BASE_URL } from '@/consts';
import { ERequisitesVariant } from '@/components/leads/components/requisites/const';
import { UICardsList, UILoadingOverlay } from '@/common/finbox-ui-kit';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { Button, DatePicker, Grid, Input, Modal } from '@/common/ui';
import { joiSchema } from '@/libs/joi';

type TForm = {
    type: ERequisitesVariant,
    contractId: number;
    contractDate: DateTime;
    contractSum: number;
}

const scheme = joiSchema((joi) => joi.object<TForm>({
    type: joi.string().when('$typeRequired', {
        is: true,
        then: joi.string().valid(...Object.values(ERequisitesVariant)).required(),
        otherwise: joi.string().optional().allow(null),
    }),
    contractId: joi.number().min(1).required(),
    contractDate: joi.luxon().required(),
    contractSum: joi.number().min(1).required(),
}));


type TLeadsSendRequisitesProps = {
    open: boolean,
    onClose: () => void,
    clientId: number,
    phone: string,
    contactPhone: string,
    email: string,
    clientInitials: TInitials,
};
export const ClientSendRequisites: React.FC<TLeadsSendRequisitesProps> = ({
    open,
    onClose,
    clientId,
    phone,
    contactPhone,
    email,
    clientInitials,
}) => {
    const [ isRequisitesShowed, setIsRequisitesShowed ] = useState(false);
    const [ isSendShowed, setIsSendShowed ] = useState(false);

    const form = useForm<TForm>({
        resolver: scheme,
        values: {
            type: null,
            contractId: clientId,
            contractSum: null,
            contractDate: null,
        },
        context: {
            typeRequired: isRequisitesShowed,
        },
    });

    const errors = form.formState.errors;

    const { loading: isLoading, fetch } = useApiClient({
        url: `${ API_BASE_URL }/requisites/${ clientId }/contract/`,
    });

    useEffect(() => {
        if (open) {
            form.reset();
            setIsRequisitesShowed(false);
            setIsSendShowed(false);
            fetch().then((data) => {
                form.setValue('contractId', data.contractID || clientId);
                form.setValue('contractSum', data.reward);
                form.setValue('contractDate', data.contractSignDate ? DateTime.fromFormat(data.contractSignDate, FORMAT.DATE) : null);
            });
        }
    }, [ open, fetch, clientId, form ]);

    const handlerSubmit = React.useCallback(() => {
        setIsRequisitesShowed(true);
    }, []);

    const handlerClickSend = React.useCallback(() => {
        setIsRequisitesShowed(false);
        setIsSendShowed(true);
    }, []);

    const handlerClickBack = React.useCallback(() => {
        setIsRequisitesShowed(true);
        setIsSendShowed(false);
    }, []);

    const handlerClickType = React.useCallback((type: ERequisitesVariant) => () => {
        form.setValue('type', type);
    }, [ form ]);

    const type = form.watch('type');

    return (
        <Modal open={ open } onClose={ onClose } size={ (type && isRequisitesShowed) ? 'large' : 'normal' }>
            <Modal.Header>Реквизиты для оплаты</Modal.Header>
            <Modal.Content>
                { !isRequisitesShowed && !isSendShowed && (
                    <form id='requisites' onSubmit={ form.handleSubmit(handlerSubmit) } className='relative' noValidate>
                        { isLoading && (
                            <UILoadingOverlay active/>
                        ) }
                        <Grid>
                            <Grid.Row columns={ 3 }>
                                <Grid.Col>
                                    <Input.Controlled
                                        control={ form.control }
                                        type='text'
                                        label='Номер договора'
                                        name='contractId'
                                        error={ errors.contractId }
                                        required
                                    />
                                </Grid.Col>
                                <Grid.Col>
                                    <DatePicker.Controlled
                                        control={ form.control }
                                        label='Дата договора'
                                        name='contractDate'
                                        error={ errors.contractDate as FieldError }
                                        required
                                    />
                                </Grid.Col>
                                <Grid.Col>
                                    <Input.Controlled
                                        control={ form.control }
                                        type='number'
                                        label='Сумма договора'
                                        name='contractSum'
                                        error={ errors.contractSum }
                                        postfix='руб.'
                                        renderValue={ (value) => formatPrice(value, false) }
                                        required
                                    />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid>
                    </form>
                ) }
                { !type && isRequisitesShowed && (
                    <UICardsList columns={ 2 }>
                        <UICardsList.Item
                            title='ИП Фельк Р.В.'
                            upperTitle='ВТБ Банк'
                            subTitle='Договор с клиентом заключен с ИП'
                            // disabled={type === ERequisitesVariant.INDIVIDUAL}
                            onClick={ handlerClickType(ERequisitesVariant.INDIVIDUAL) }
                            // basic={type !== ERequisitesVariant.INDIVIDUAL}
                        />
                        <UICardsList.Item
                            title='ИП Фельк Р.В.'
                            upperTitle='МТС банк'
                            subTitle='Договор с клиентом заключен с ИП'
                            // disabled={type === ERequisitesVariant.INDIVIDUAL}
                            onClick={ handlerClickType(ERequisitesVariant.MTS) }
                            // basic={type !== ERequisitesVariant.INDIVIDUAL}
                        />
                        <UICardsList.Item
                            title='ООО "Фонд финансирования"'
                            upperTitle='Сбер Банк'
                            subTitle='Договор с клиентом заключен с ООО'
                            // disabled={type === ERequisitesVariant.LEGAL}
                            onClick={ handlerClickType(ERequisitesVariant.LEGAL) }
                            // basic={type !== ERequisitesVariant.LEGAL}
                        />
                        <UICardsList.Item
                            title='ООО "Фонд финансирования"'
                            upperTitle='АО "Т-Банк"'
                            subTitle='Договор с клиентом заключен с ООО'
                            // disabled={type === ERequisitesVariant.LEGAL}
                            onClick={ handlerClickType(ERequisitesVariant.TBANK) }
                            // basic={type !== ERequisitesVariant.LEGAL}
                        />
                    </UICardsList>
                ) }
                { type && isRequisitesShowed && (
                    <RequisitesView
                        type={ type }
                        contractId={ form.getValues('contractId') }
                        contractDate={ form.getValues('contractDate')?.toFormat(FORMAT.DATE) }
                        contractSum={ form.getValues('contractSum') }
                        clientInitials={ clientInitials }
                    />
                ) }
                { isSendShowed && (
                    <RequisitesSend
                        type={ type }
                        contractId={ form.getValues('contractId') }
                        contractDate={ form.getValues('contractDate')?.toFormat(FORMAT.DATE) }
                        contractSum={ form.getValues('contractSum') }
                        phone={ phone }
                        contactPhone={ contactPhone }
                        email={ email }
                        clientInitials={ clientInitials }
                    />
                ) }
            </Modal.Content>
            <Modal.Actions>
                { !isRequisitesShowed && !isSendShowed && (
                    <Button
                        form='requisites'
                        content='Продолжить'
                        disabled={ isLoading }
                        primary
                    />
                ) }
                { isRequisitesShowed && type && (
                    <Button
                        icon='paper-plane'
                        content='Отправить'
                        onClick={ handlerClickSend }
                        primary
                    />
                ) }
                { isSendShowed && (
                    <Button
                        content='Назад'
                        onClick={ handlerClickBack }
                    />
                ) }
                <Button
                    content='Закрыть'
                    onClick={ onClose }
                />
            </Modal.Actions>
        </Modal>
    );
};
