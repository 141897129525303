import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { BroadcastChannel } from 'broadcast-channel';
import { atom, useAtom } from 'jotai';
import { random } from 'lodash';
import { API_URLS, SOCKET_EVENT } from '@/consts';
import { TNotification, TNotificationId } from '@/common/user-notifications/notifications.types';
import { useSocketsContext } from '@/libs/sockets/sockets.context';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { sleep } from '@/utils/async-sleep';

const infoSound = new Audio('/assets/sounds/mixkit-correct-answer-tone-2870.wav');
const warningSound = new Audio('/assets/sounds/warning.mp3');
const bc = new BroadcastChannel('notifications');

async function createSystemNotification(data: TNotification): Promise<Notification | null> {
    const key = `ntf-${ data.id }`;
    await sleep(random(100, 500));
    const exists = localStorage.getItem(key);
    if (exists) {
        return null;
    }
    localStorage.setItem(key, '1');
    const notification = new Notification(data.title, {
        body: data.body,
        icon: 'https://ffo24.ru/assets/images/favicon/android-chrome-192x192.png',
        requireInteraction: true,
        tag: data.id,
    });
    notification.onclick = () => {
        window.open(data.link);
    };
    setTimeout(() => {
        localStorage.removeItem(key);
    }, 1000);
    return notification;
}


const soundsMap = {
    'warning': warningSound,
};


interface INotificationsContext {
    removeNotification: (notification: TNotification) => Promise<void>;
    clearNotifications: () => Promise<void>;
    handleClickNotification: (notification: TNotification) => Promise<void>;
    isLoading: boolean;
    isLoadingRemove: TNotificationId;
    isLoadingClear: boolean;
}


const NotificationsContext = React.createContext<INotificationsContext>({
    removeNotification: () => null,
    clearNotifications: () => null,
    handleClickNotification: () => null,
    isLoading: false,
    isLoadingRemove: null,
    isLoadingClear: false,
});

export function useNotificationsContext() {
    return React.useContext(NotificationsContext);
}

export const NotificationState = atom<TNotification[]>([]);
export const NotificationCountState = atom<number>(0);

export const NotificationsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [ notifications, setNotifications ] = useAtom(NotificationState);
    const [ , setCount ] = useAtom(NotificationCountState);
    const [ isLoadingRemove, setIsLoadingRemove ] = React.useState(null);
    const { subscribe, unsubscribe } = useSocketsContext();
    const navigate = useNavigate();

    const { data, loading: isLoading } = useApiClient({
        url: API_URLS.NOTIFICATIONS,
        initialFetch: true,
    });

    const { fetch: fetchRemove } = useApiClient({
        method: 'delete',
        url: API_URLS.NOTIFICATIONS,
    });

    const { fetch: fetchClear, loading: isLoadingClear } = useApiClient({
        method: 'delete',
        url: API_URLS.NOTIFICATIONS,
    });

    useEffect(() => {
        if (data) {
            setNotifications(data.data);
            setCount(data.count);
        }
    }, [ data, setCount, setNotifications ]);

    const removeNotification = useCallback(async function removeNotification(notification: TNotification) {
        setIsLoadingRemove(notification.id);
        try {
            await fetchRemove(null, {
                url: `${ API_URLS.NOTIFICATIONS }${ notification.id }/`,
            });
        } finally {
            if (notification.systemNotification) {
                notification.systemNotification.close();
            }
            setIsLoadingRemove(null);
        }

        setNotifications((current) => current.filter((n) => n.id !== notification.id));
        setCount((prev) => prev - 1);

        await bc.postMessage({
            type: 'NOTIFICATION_REMOVED',
            notificationId: notification.id,
        });
    }, [ fetchRemove, setCount, setNotifications ]);

    const clearNotifications = useCallback(async function clearNotifications(event = false) {
        if (!event) {
            await fetchClear();
        }
        notifications.forEach((n) => {
            if (n.systemNotification) {
                n.systemNotification.close();
            }
        });
        setNotifications([]);
        setCount(0);
        if (!event) {
            await bc.postMessage({
                type: 'NOTIFICATIONS_CLEARED',
            });
        }
    }, [ fetchClear, notifications, setCount, setNotifications ]);

    const handleClickNotification = useCallback(async (notification: TNotification) => {
        if (!notification.link) {
            return;
        }
        if (notification.target === 'blank') {
            window.open(notification.link);
        } else {
            navigate(notification.link);
        }
        await removeNotification(notification);
    }, [ navigate, removeNotification ]);

    const handlerNewNotification = useCallback(async (data: TNotification) => {
        data.systemNotification = data.title.includes('Новый клиент')
            ? await createSystemNotification(data)
            : null;

        setNotifications((current) => [
            data,
            ...current,
        ]);

        setCount((current) => current + 1);

        toast((
            <div>
                <b>{ data.title }</b> <br/>
                { data.body }
            </div>
        ), {
            type: data.level,
            onClick: () => handleClickNotification(data),
            closeOnClick: !!data.link,
        });
        if (data.playSound) {
            await (soundsMap[data.level] || infoSound).play();
        }
    }, [ handleClickNotification, setCount, setNotifications ]);

    useEffect(() => {
        subscribe(SOCKET_EVENT.NOTIFICATION, handlerNewNotification);

        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission();
        }

        return () => {
            unsubscribe(SOCKET_EVENT.NOTIFICATION, handlerNewNotification);
        };
    }, [ handlerNewNotification, subscribe, unsubscribe ]);

    const handlerBCEvent = useCallback(async ({ type, notificationId }) => {
        if (type === 'NOTIFICATIONS_CLEARED') {
            await clearNotifications(true);
            return;
        }

        setNotifications((current) => current.filter((i) => i.id !== notificationId));
        setCount((current) => current - 1);
    }, [ clearNotifications, setCount, setNotifications ]);

    useEffect(() => {
        // TODO: переделать но сокеты в связке с беком
        bc.addEventListener("message", handlerBCEvent);
        return () => {
            bc.removeEventListener("message", handlerBCEvent);
        };
    }, [ handlerBCEvent ]);

    return (
        <NotificationsContext value={ {
            removeNotification,
            clearNotifications,
            isLoading,
            isLoadingRemove,
            isLoadingClear,
            handleClickNotification,
        } }>
            { children }
        </NotificationsContext>
    );
};
