import { Extension, Root } from 'joi';
import { Validator } from 'finbox-ui-kit/libs/validator';

export const JoiBankCorAccountExtension = (joi: Root): Extension => ({
    type: 'bankCorAccount',
    base: joi.string(),
    messages: {
        'bankAccount.format': 'некорректно указан номер кор. счета',
    },
    coerce(value: string) {
        return { value: value?.trim() };
    },
    validate(value, helpers) {
        if (!helpers.state.ancestors[0]?.bik) {
            return { value };
        }
        const isValid = Validator.bankCorAccount(value, helpers.state.ancestors[0]?.bik);
        if (isValid !== true) {
            return { value, errors: helpers.error('bankAccount.format') };
        }
        return { value };
    },
});
