import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { UITopMenu } from 'finbox-ui-kit/components/top-menu';
import { TCounters, TFUITopMenuItem } from 'finbox-ui-kit/components/top-menu/desktop/top-menu';
import { API_BASE_URL_V2, UserRole } from '@/consts';
import { TUser } from '@/types/manager';
import './style.scss';
import { TimeTracker } from '@/common/time-tracker';
import NotificationsIcon from '@/common/user-notifications';
import { useAuthContext } from '@/context/auth.context';
import { buildTopMenu } from '@/components/top-menu/top-menu-stucture';
import { UserAvatar } from '@/common/user-avatar';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { API_COUNTERS_GLOBAL } from '@/components/top-menu/_consts';
import { SearchGlobal } from './components/search';


interface ITopMenuProps {
    isAdmin: boolean;
    user: TUser;
}


const TopMenu = ({ user }: ITopMenuProps) => {
    const navigate = useNavigate();
    const { doLogoutUser, checkRoles } = useAuthContext();

    const { data: counters } = useApiClient<TCounters>({
        url: API_COUNTERS_GLOBAL,
        initialFetch: checkRoles([ UserRole.admin, UserRole.manager, UserRole.creditors_manager ]),
        initialData: {},
    });
    const { fetch } = useApiClient({
        method: 'post',
        url: `${ API_BASE_URL_V2 }/auth/logout`,
    })
    // useEffect(() => {
    //     async function handler() {
    //         await fetchCounters();
    //     }
    //     subscribe(SOCKET_EVENT.UPDATE_GLOBAL_COUNTERS, handler);
    //     return () => {
    //         unsubscribe(SOCKET_EVENT.UPDATE_GLOBAL_COUNTERS, handler);
    //     }
    // }, [ fetchCounters, subscribe, unsubscribe ]);

    const logoutClickHandler = useCallback(async (e: MouseEvent) => {
        e.preventDefault();
        try {
            await fetch();
        } catch (err) {
            toast.error('Ошибка выхода');
            throw err;
        }
        toast.success('До скорых встреч! ❤');
        await doLogoutUser();
        navigate('/');
    }, [ doLogoutUser, fetch, navigate ]);

    const menuItems = useMemo<TFUITopMenuItem[]>(() => [
        ...buildTopMenu(user),
        ...checkRoles([ UserRole.admin, UserRole.manager, UserRole.creditors_manager, UserRole.hr ]) ? [
            {
                render: (<SearchGlobal key='global_search'/>),
            },
        ] : [],
        ...checkRoles([ UserRole.admin, UserRole.manager ]) ? [
            {
                right: true,
                render: (
                    <TimeTracker/>
                ),
            },
        ] : [],
        {
            name: (
                <div style={ { display: 'flex', alignItems: 'center' } }>
                    <div style={ { width: 44, padding: 8, verticalAlign: 'middle', marginRight: 2 } }>
                        <UserAvatar user={ user }/>
                    </div>
                    <div style={ { marginRight: 2 } }>{ user.name }</div>
                </div>
            ),
            right: true,
            items: [
                {
                    name: 'Профиль',
                    to: '/profile/',
                },
                {
                    name: 'График работы',
                    to: '/profile/schedule/',
                },
                {
                    name: 'Выход',
                    to: '/user/logout/',
                    onClick: logoutClickHandler,
                },
            ],
        },
    ], [ checkRoles, logoutClickHandler, user ]);

    return (
        <UITopMenu
            logoText={ (
                <img src='/assets/images/logo.png' style={ {
                    width: 24,
                } } alt='Фонд Финансирования'/>
            ) }
            logoBackgroundColor='#bf0000'
            logoTextColor='#fff'
            logoLink='/'
            items={ menuItems }
            counters={ counters }
            icons={ checkRoles([ UserRole.admin, UserRole.manager, UserRole.creditors_manager ]) && [
                {
                    render: (
                        <NotificationsIcon/>
                    ),
                },
            ] }
        />
    );
};

export default TopMenu;
