import React, { useState, useCallback, useEffect, useRef } from 'react';
import { UILoadingOverlay } from 'finbox-ui-kit';
import { useDownloadFile } from '@/utils/hooks/use-download-file';
import { Button, Modal } from '@/common/ui';


type TUIPreviewDocumentProps = {
    title: string,
    url: string,
    open: boolean,
    onClose: () => void,
    downloadWordLink?: string,
    downloadWordPDFLink?: string,
}
export const UIPreviewDocument: React.FC<TUIPreviewDocumentProps> = ({
    title,
    url,
    open,
    onClose,
    downloadWordLink,
    downloadWordPDFLink,
}) => {
    const frameRef = useRef<HTMLIFrameElement | null>(null);
    const [ file, setFile ] = useState<Blob>(null);
    const { isLoading: isLoadingWord, download: downloadWord } = useDownloadFile();
    const { isLoading: isLoadingPDF, download: downloadPDF } = useDownloadFile();
    const { download: downloadPreviewFile, isLoading: loadingDownloadPreview } = useDownloadFile();

    useEffect(() => {
        if (open && url) {
            setFile(null);
            downloadPreviewFile(url, false, true, true)
                .then(setFile);
        }
    }, [ downloadPreviewFile, open, url ]);

    const handlerClickDownloadWord = useCallback(async () => {
        await downloadWord(downloadWordLink);
    }, [ downloadWord, downloadWordLink ]);

    const handlerClickDownloadPDF = useCallback(async () => {
        await downloadPDF(downloadWordPDFLink);
    }, [ downloadPDF, downloadWordPDFLink ]);

    return (
        <Modal open={ open } size='huge' onClose={ onClose } showCloseButton>
            <Modal.Header>
                <div
                    title={ title || 'Просмотр документа' }
                    style={ {
                        flex: 1,
                        maxWidth: '60%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    } }
                >
                    { title || 'Просмотр документа' }
                </div>

                <div style={{ display: 'flex', gap: '0.5em' }}>
                    <Button
                        icon='arrows-rotate'
                        size='tiny'
                        color='white'
                        onClick={ () => {
                            downloadPreviewFile(url, false, true, true)
                                .then(setFile);
                        } }
                    />
                    { downloadWordLink && (
                        <Button
                            content='Скачать Word'
                            icon='file-word'
                            iconColor='blue'
                            onClick={ handlerClickDownloadWord }
                            loading={ isLoadingWord }
                            size='tiny'
                            color='white'
                        />
                    ) }
                    { downloadWordPDFLink && (
                        <Button
                            content='Скачать PDF'
                            icon='file-pdf'
                            iconColor='red'
                            onClick={ handlerClickDownloadPDF }
                            loading={ isLoadingPDF }
                            size='tiny'
                            color='white'
                        />
                    ) }
                </div>
            </Modal.Header>
            <Modal.Content className='relative p0' style={ { flex: 1 } }>
                { loadingDownloadPreview && (
                    <UILoadingOverlay active/>
                ) }
                { file && (
                    <iframe
                        ref={ frameRef }
                        src={ window.URL.createObjectURL(file) }
                        frameBorder='0'
                        style={ {
                            position: 'relative',
                            zIndex: 2,
                            background: 'transparent',
                            overflow: 'hidden',
                            height: '100%',
                            width: '100%',
                            outline: 'none !important',
                            border: 'none !important',
                            boxShadow: 'none !important',
                            marginTop: -2,
                        } }
                        height='100%'
                        width='100%'
                        referrerPolicy='no-referrer'
                    ></iframe>
                ) }
            </Modal.Content>
        </Modal>
    );
};

export default UIPreviewDocument;
