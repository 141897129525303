import React, { useRef, useState } from 'react';

import './notifications.style.scss';
import cn from 'classnames';
import { useAtomValue } from 'jotai/index';
import { UILabel, UIPopup } from 'finbox-ui-kit';
import { UIIcon } from 'finbox-ui-kit/components/icon';
import { NotificationCountState } from '@/context/notifications.context';
import { NotificationsList } from './notifications-list';

export const NotificationsIcon = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [ open, setOpen ] = useState(false);
    const count = useAtomValue(NotificationCountState);

    const handlerClickIcon = () => {
        setOpen(true);
    };

    const handlerClose = () => {
        setOpen(false);
    };

    return (
        <div ref={ ref } className={ cn('notifications', { '-open': open, '-animated': count > 0 }) }>
            <div className='notifications-icon' onClick={ handlerClickIcon }>
                <UIIcon className='notifications-icon-icon' name='bell' size='big' color='white' type={ count > 0 ? 'solid' : 'regular' }/>
                { count > 0 && (
                    <UILabel className='ml-5' color='red'>{ count < 100 ? count : '99+' }</UILabel>
                ) }
            </div>
            <UIPopup.Wrapper>
                <UIPopup
                    targetRef={ ref }
                    open={ open }
                    onClickOutside={ handlerClose }
                    position='bottom right'
                    className='notificationsPopup'
                >
                    <NotificationsList
                        onClose={ handlerClose }
                    />
                </UIPopup>
            </UIPopup.Wrapper>
        </div>
    );
};
