import React, { useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { UIGrid, UIIconButton, UIParam } from 'finbox-ui-kit';
import { TInitials } from 'finbox-ui-kit/types';
import { formatInitialsShort } from 'finbox-ui-kit/utils/format-initials';
import { Base64Encode, blobToBase64 } from '@/utils/base64';
import { Phone } from '@/common';
import { Email } from '@/common/email';
import { formatPrice } from '@/utils';
import { useDownloadFile } from '@/utils/hooks/use-download-file';
import { API_BASE_URL } from '@/consts';
import { ERequisitesVariant, requisites } from '@/components/leads/components/requisites/const';
import { calculateNds } from '@/utils/calculate-nds';


const STYLE = {
    REQUISITES_TABLE: {
        TABLE: {
            width: '100%',
        },
        TD: {
            border: '1px solid #eceeef',
            padding: '8px 16px',
        },
    },
};

type TRequisitesSendProps = {
    type: ERequisitesVariant,
    contractId: number,
    contractDate: string,
    contractSum: number,
    phone: string,
    contactPhone: string,
    email: string,
    clientInitials: TInitials,
}
export const RequisitesSend: React.FC<TRequisitesSendProps> = ({
    type,
    contractId,
    contractDate,
    contractSum,
    phone,
    contactPhone,
    email,
    clientInitials,
}) => {
    const { download } = useDownloadFile();
    const [ QRCode, setQRCode ] = React.useState<Blob>();
    const nds = useMemo(() => calculateNds(contractSum), [ contractSum ]);

    React.useEffect(() => {
        if (contractId && contractDate) {
            download(
                `${ API_BASE_URL }/requisites/qr-code/?${new URLSearchParams({
                    type,
                    contractId: contractId?.toString(),
                    contractDate,
                    contractSum: formatPrice(nds.amount),
                    commissionNDSAmount: formatPrice(nds.NDSAmount),
                    clientInitials: formatInitialsShort(clientInitials),
                })}`,
                false,
                true,
                true,
            )
                .then(blobToBase64)
                .then(setQRCode);
        }
    }, [ download, type, contractId, contractDate, contractSum, clientInitials, nds.amount, nds.NDSAmount ]);

    const link = React.useMemo(
        () => `https://infond24.ru/requisites/?contract=${ Base64Encode(encodeURIComponent([
            type,
            contractId,
            contractDate,
            contractSum,
            formatInitialsShort(clientInitials),
        ].join('|'))) }`,
        [ type, contractDate, contractId, contractSum, clientInitials ],
    );

    const emailText = React.useMemo(() => requisites ?? renderToString((
        <table style={ { width: '100%' } }>
            <tbody>
                <tr>
                    <td>
                        <table className='requisites-table' style={ STYLE.REQUISITES_TABLE.TABLE } cellPadding={ 0 } cellSpacing={ 0 }>
                            <tbody>
                                <tr>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>Организация:</td>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>{ requisites.get(type).name }</td>
                                </tr>
                                <tr>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>ИНН:</td>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>{ requisites.get(type).inn }</td>
                                </tr>
                                <tr>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>КПП:</td>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>{ requisites.get(type).kpp || '-' }</td>
                                </tr>
                                <tr>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>Счёт:</td>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>{ requisites.get(type).account }</td>
                                </tr>
                                <tr>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>Банк:</td>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>{ requisites.get(type).bankName }</td>
                                </tr>
                                <tr>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>БИК:</td>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>{ requisites.get(type).bankBIK }</td>
                                </tr>
                                <tr>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>Кор. счёт:</td>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>{ requisites.get(type).corAccount }</td>
                                </tr>
                                <tr>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>Назначение платежа:</td>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>
                                        Оплата по договору №{ contractId } от { contractDate }, { formatInitialsShort(clientInitials) } в размере { formatPrice(nds.amount) }{ ' ' }
                                        { requisites.get(type).withNDS ? `в том числе НДС 20% ${ formatPrice(nds.NDSAmount) }` : 'НДС не облагается' }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>Сумма:</td>
                                    <td style={ STYLE.REQUISITES_TABLE.TD }>
                                        { formatPrice(nds.amount) } { requisites.get(type).withNDS ? `в том числе НДС 20% ${ formatPrice(nds.NDSAmount) }` : 'НДС не облагается' }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td style={ { textAlign: 'center' } }>
                        <img
                            src={ `data:image/png;base64,` + QRCode }
                            alt='QR код для оплаты через приложение'
                            style={ { maxWidth: '300px' } }
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    )), [ type, contractId, contractDate, clientInitials, nds.amount, nds.NDSAmount, QRCode ]);

    const handlerClickCopyLink = React.useCallback(() => {
        copy(link);
        toast.info('Ссылка скопирована');
    }, [ link ]);

    return (
        <UIGrid>
            <UIGrid.Row columns={ 3 }>
                <UIGrid.Col fluid>
                    <UIParam
                        label='Ссылка на реквизиты'
                    >
                        <a
                            href={ link }
                            target='_blank'
                            style={{
                                display: 'inline-block',
                                width: 'calc(100% - 40px)',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            { link }
                        </a>
                        <UIIconButton
                            icon='copy'
                            iconType='light'
                            color='grey'
                            className='cursor-pointer ml-5'
                            onClick={ handlerClickCopyLink }
                        />
                    </UIParam>
                </UIGrid.Col>
                <UIGrid.Col>
                    <UIParam
                        label='Телефон'
                    >
                        <Phone
                            number={ phone }
                            text={ `Ссылка на реквизиты для оплаты \n${ link }` }
                        />
                    </UIParam>
                </UIGrid.Col>
                <UIGrid.Col>
                    <UIParam
                        label='Телефон для сообщений'
                    >
                        { contactPhone && (
                            <Phone
                                number={ contactPhone }
                                text={ `Ссылка на реквизиты для оплаты \n${ link }` }
                            />
                        ) }
                        { !contactPhone && '-' }
                    </UIParam>
                </UIGrid.Col>
                <UIGrid.Col>
                    <UIParam
                        label='Email'
                    >
                        { email && (
                            <Email
                                email={ email }
                                text={ emailText }
                                subject='Реквизиты для оплаты'
                            />
                        ) }
                        { !email && '-' }
                    </UIParam>
                </UIGrid.Col>
            </UIGrid.Row>
        </UIGrid>
    );
};
