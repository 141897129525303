/* eslint-disable no-console */
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { socket } from '@/libs/socket';
import { getJwtToken } from '@/libs/jwt-token';
import { Button, Icon, Modal } from '@/common/ui';

const debug = false;

type TSocketsContext = {
    connect(): void;
    disconnect(): void;
    subscribe(event: string, callback: (data: any) => void): void;
    unsubscribe(event: string, callback: (data: any) => void): void;
};


const SocketsContext = React.createContext<TSocketsContext>({} as TSocketsContext);

export function useSocketsContext() {
    return React.useContext(SocketsContext);
}

type TSocketsProps = {
    children?: ReactNode
};
export const SocketsProvider = ({ children }: TSocketsProps) => {
    const [ connectionLost, setConnectionLost ] = useState(false);
    const [ isConnectionInit, setIsConnection ] = useState(false);

    useEffect(() => {
        if (debug) { console.log('[Sockets] INIT SOCKETS'); }
        socket.auth = async (cb) => {
            const token = getJwtToken();
            cb({ token });
        };

        socket.on('connect', () => {
            if (debug) { console.log('[Sockets] connected'); }
            setConnectionLost(false);
        });

        socket.on('connect_error', (err) => {
            console.log('[Sockets] connect_error');
            console.log(err);
        });

        socket.on('disconnect', (err) => {
            console.log('[Sockets] disconnected');
            console.error(err);
            setConnectionLost(true);
        });

        return () => {
            socket.disconnect();
        };
    }, []);


    const connect = useCallback(() => {
        if (debug) { console.log('[Sockets] connect'); }
        socket.connect();
        setIsConnection(true);
    }, []);

    const disconnect = useCallback(() => {
        socket.disconnect();
        setIsConnection(false);
    }, []);

    const subscribe = useCallback((event: string, callback: (data: any) => void) => {
        if (debug) { console.log('[Sockets] subscribe', event); }
        socket.on(event, callback);
    }, []);

    const unsubscribe = useCallback((event: string, callback: (data: any) => void) => {
        if (debug) { console.log('[Sockets] unsubscribe', event); }
        socket.off(event, callback);
    }, []);

    return (
        <SocketsContext.Provider value={ {
            connect,
            disconnect,
            subscribe,
            unsubscribe,
        } }>
            { children }
            <Modal open={ connectionLost && isConnectionInit } size='tiny' closeOnDimmerClick={ false }>
                <Modal.Content className='text-center pb2'>
                    <h3 className='mb2 mt1'>Потеряно соединение с сервером</h3>
                    <div className='fz36 mt1 mb1'>
                        <Icon name='signal-slash' type='thin' color='red'/>
                    </div>
                    Идёт повторное подключение...
                    <Button
                        className='mt1'
                        content='Перезагрузить страницу'
                        onClick={ () => window.location.reload() }
                    />
                </Modal.Content>
            </Modal>
        </SocketsContext.Provider>
    );
};
