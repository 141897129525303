import { DateTime } from 'luxon';
import OriginJoi, { ArraySchema, IsNonPrimitiveSubsetUnion, NullableType, ObjectSchema, Root, StrictSchemaMap, StringSchema } from 'joi';

import { JoiLuxonExtension, LuxonSchema } from './extensions/joi-luxon.extension';
import { JoiPhoneExtension, PhoneSchema } from './extensions/joi-phone.extension';
import { JoiInnExtension } from './extensions/joi-inn.extension';
import { JoiInitialsExtension } from './extensions/joi-initials.extension';
import { JoiRangeExtension } from './extensions/joi-range.extension';
import { JoiStringNumericExtension } from './extensions/joi-string-numeric.extension';
import { FileSchema, JoiFileExtension } from './extensions/joi-file.extension';
import { JoiBankAccountExtension } from './extensions/joi-bank-account.extension';
import { JoiBankCorAccountExtension } from './extensions/joi-bank-cor-account.extension';


export interface ExtendedJoi extends Root {
    phone(): PhoneSchema;

    inn(): StringSchema;

    luxon(): LuxonSchema;

    initials(): ObjectSchema;

    range(): ArraySchema;

    stringNumeric(): StringSchema;

    file(): FileSchema;

    bankAccount(): StringSchema;

    bankCorAccount(): StringSchema;
}

export type CustomObjectPropertiesSchema<T = any> =
    true extends IsNonPrimitiveSubsetUnion<Exclude<T, undefined | null>>
        ? OriginJoi.AlternativesSchema
        : T extends NullableType<string>
            ? OriginJoi.StringSchema
            : T extends NullableType<number>
                ? OriginJoi.NumberSchema
                : T extends NullableType<bigint>
                    ? OriginJoi.NumberSchema
                    : T extends NullableType<boolean>
                        ? OriginJoi.BooleanSchema
                        : T extends NullableType<Date>
                            ? OriginJoi.DateSchema
                            : T extends NullableType<Buffer>
                                ? OriginJoi.BinarySchema
                                : T extends NullableType<Array<any>>
                                    ? OriginJoi.ArraySchema
                                    : T extends NullableType<object>
                                        ? (StrictSchemaMap<T> | ObjectSchema<T>)
                                        : T extends NullableType<DateTime>
                                            ? LuxonSchema
                                            : never

export const Joi: ExtendedJoi =
    OriginJoi
        .extend(JoiPhoneExtension)
        .extend(JoiLuxonExtension)
        .extend(JoiInnExtension)
        .extend(JoiInitialsExtension)
        .extend(JoiRangeExtension)
        .extend(JoiStringNumericExtension)
        .extend(JoiBankAccountExtension)
        .extend(JoiBankCorAccountExtension)
        .extend(JoiFileExtension);
