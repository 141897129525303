import React from 'react';
import { Outlet } from 'react-router';
import { Footer } from '@/common/footer';
import TopMenu from '@/components/top-menu';
import { useAuthContext } from '@/context/auth.context';


const AppLayout = () => {
    const { user } = useAuthContext();
    if (!user) return null;
    return (
        <main>
            <TopMenu isAdmin={ user?.admin } user={ user }/>
            <div className='main-block'>
                <Outlet />
            </div>
            <Footer />
        </main>
    );
};

export default AppLayout;
