import round from 'lodash/round';

export function calculateNds(amount: number): {
    amount: number;
    NDSAmount: number;
    amountWithoutNDS: number;
} {
    const commissionNDSAmount = amount ? round(amount * 20 / 120, 2) : 0;
    return {
        amount,
        NDSAmount: commissionNDSAmount,
        amountWithoutNDS: amount - commissionNDSAmount,
    }
}